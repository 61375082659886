.partners {
    background-color: $color_footer;
    padding: 1rem 0;
    box-shadow: 0 0 13px 2px $color_shadow;
    border-top: 2px solid $color_shadow;
    margin-top: 1rem;
    @include media-breakpoint-down(md) {
        padding: 2rem;
    }

    .row{
        margin: 0 -.5rem;
    }

    .partner-col {
        padding: .5rem 1rem;
    }

    .partner {
        padding-top: 90%;
        position: relative;

        &__content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: .5rem .8rem;

            background-color: $color_background;
            border: 1px solid $color_shadow;

            &__logo {
                height: 65%;
                background: 50% 50% no-repeat;
                background-size: contain;
                padding: .5rem .5rem .5rem .5rem;

                img {
                    margin: 0 auto;
                    object-fit: contain;
                }
            }

            &__text {
                width: 100%;
                height: 35%;
                display: table;
                font-size: .85rem;
                line-height: 1rem;

                .center {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                }
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}