#newsletter {
    input[type=email] {
        width: 100%;
        border-radius: 1px;
        border: 1px solid #ddd;
        padding: 0.5rem 0.5rem;
        font-size: 0.9rem;
    }

    .input-label {
        label {
            padding: 0.5rem 0;
        }
    }

    input[type=checkbox], input[type=radio] {
        margin: 0 10px;
    }

    a:hover, input[type=submit]:hover {
        text-decoration: underline;
        color: white;
    }

    .update:hover {
        color: #c7151d;
    }

    .button {
        cursor: pointer;
        background-color: $color_primary;
        padding: .75rem 1rem;
        color: white;
        display: block;
        font-size: .8rem;
        font-weight: 600;
        text-transform: uppercase;
        border: none;
    }

    .frequency {
        label {
            margin-right: 15px;
        }
    }

    .confirm-cancel {
        margin-top: 20px;
    }

    @include media-breakpoint-down(xs) {
        .cancel {
            margin-top: 10px;
        }
    }
}