.header {

    &__top {
        padding: .4rem 0 .2rem 0;

        .navbar {
            flex-direction: column;
            align-items: flex-end;
            font-size: .85rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
            @include media-breakpoint-down(md) {
                max-width: 100%;
            }

            &-right {
                display: flex;
            }

            &-nav {
                flex-direction: row;
            }

            &-menu {
                align-items: center;

                .nav-item {
                    padding: 0 1.1rem;
                }
            }

            &-lang {
                margin: 0 1rem;

                .nav-item, .lang-item {
                    text-transform: uppercase;
                    padding: .45rem;
                    margin: auto .2rem;
                    border-radius: 3px;
                }
                .nav-item.active, .lang-item.current-lang {
                    border: 1px solid $color_shadow;
                }
            }

            &-search {
                display: flex;
                align-items: center;
                border-radius: 3px;
                border: 1px solid #ddd;
                input {
                    border: none;
                    padding: 0 .25rem;
                    margin-right: .2rem;
                    width: $menu_main_item_width + 0.2rem;
                }
                button{
                    background-color: #fff;
                }

                i {
                    // margin-left: -1.6rem;
                    font-size: 1.1rem;
                    cursor: pointer;
                    // margin-right: .5rem;
                }
            }
        }
    }

    &__main {
        box-shadow: 0 0 20px 0px $color_shadow;
        border-top: 3px solid $color_shadow;

        .navbar {
            padding-top: 0;
            padding-bottom: 0;
            @include media-breakpoint-down(md) {
                max-width: 100%;
                padding: 0;
            }

            &-brand {
                padding: 1.5rem 1rem;
                height: 100%;
                height: $menu_main_height;
                @include media-breakpoint-down(md) {
                    flex-grow: 1;
                    margin-right: 0;
                }

                img {
                    height: 100%;
                }
            }

            &-collapse {
                z-index: 1;

                @include media-breakpoint-up(lg) {
                    height: 100%;
                }
            }

            &-menu {
                @include media-breakpoint-up(lg) {
                    height: $menu_main_height;
                }
                width: 100%;
            }

            &-nav {
                float: right;
                height: 100%;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    float: none;
                    height: auto;
                }

                @include media-breakpoint-up(lg) {
                    .nav-item:first-child {
                        .nav-item__content {
                            border-left: 1px solid $color_shadow;
                        }
                    }
                    .nav-item:last-child {
                        .nav-item__content {
                            border-right: 1px solid $color_shadow;
                        }
                    }

                    .nav-item {
                        .nav-item__content {
                            transition: box-shadow .1s ease-in;
                            border-left: .5px solid $color_shadow;
                            border-right: .5px solid $color_shadow;
                            width: $menu_main_item_width;
                            flex-direction: column;
                        }
                    }

                    .nav-item.open {
                        z-index: 1;
                        .nav-item__content {
                            box-shadow: 0 5px 20px 2px $color_shadow;
                        }
                        .nav-item__border {
                            border-bottom: 5px solid $color_background;
                        }
                    }
                }

                .nav-item {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    background-color: $color_background;
                    height: 100%;

                    &__content {
                        flex-grow: 1;
                        text-align: center;
                        font-weight: 700;
                        display: flex;
                        flex-direction: row-reverse;
                        @include media-breakpoint-down(md) {
                            padding: .5rem 0;
                        }

                        &__text {
                            flex-grow: 1;
                            padding: 1rem 2rem 0 2rem;
                            font-size: 0.9rem;
                            line-height: 1.25rem;
                            @include media-breakpoint-down(md) {
                                padding: .1rem 0;
                                text-align: left;
                            }
                        }

                        &__arrow {
                            font-size: 1.1rem;
                            transition: transform .2s ease-in;

                            @include media-breakpoint-down(md) {
                                transform: rotate(-90deg);
                                margin: 0 1rem;
                            }
                        }
                    }

                    &__border {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }

                    &__menu {
                        display: none;
                        @include media-breakpoint-down(md) {
                            padding: 0 2rem;
                        }

                        .menu-item {
                            padding: .5rem 2rem;

                            .item-child {
                                display: block;
                                padding: 0 1rem;
                                margin-top: .75rem;

                                &-has-children {
                                    &:after {
                                        color: gray;
                                        font-family: fontAwesome;
                                        content: $fa-var-chevron-right;
                                        display: block;
                                        text-decoration: none;
                                        margin: 3px 0 0;
                                        font-size: .8rem;
                                        float: right;
                                        transition: all .2s ease-in;
                                    }
                                }
                            }
                            .item-child-child{
                                display: block;
                                padding: 0 2rem;
                                margin-top: .75rem;
                            }
                        }
                    }
                }

                .nav-item.mobileopen {
                    .nav-item__content__arrow {
                        transform: none;
                    }
                }

                .nav-item.item-1 {
                    .nav-item__content {
                        &__arrow {
                            color: $color_menu_1;
                        }
                    }
                    .nav-item__border {
                        border-bottom: 5px solid $color_menu_1;
                    }
                }
                .nav-item.item-2 {
                    .nav-item__content {
                        &__arrow {
                            color: $color_menu_2;
                        }
                    }
                    .nav-item__border {
                        border-bottom: 5px solid $color_menu_2;
                    }
                }
                .nav-item.item-3 {
                    .nav-item__content {
                        &__arrow {
                            color: $color_menu_3;
                        }
                    }
                    .nav-item__border {
                        border-bottom: 5px solid $color_menu_3;
                    }
                }
                .nav-item.item-4 {
                    .nav-item__content {
                        &__arrow {
                            color: $color_menu_4;
                        }
                    }
                    .nav-item__border {
                        border-bottom: 5px solid $color_menu_4;
                    }
                }
                .nav-item.item-5 {
                    .nav-item__content {
                        &__arrow {
                            color: $color_menu_5;
                        }
                    }
                    .nav-item__border {
                        border-bottom: 5px solid $color_menu_5;
                    }
                }
                @include media-breakpoint-up(lg) {
                    .nav-item.current{
                        height: 110%;
                        z-index: 1;
                        .nav-item__content {
                            box-shadow: 0 5px 20px 2px $color_shadow;
                        }
                    }
                }
            }

            &-mobile-top {
                @include media-breakpoint-down(md) {
                    display: flex;
                    width: 100%;
                    transition: all .1s ease-in;
                }

                &.open {
                    @include media-breakpoint-down(md) {
                        box-shadow: inset 0 -13px 15px -10px $color_shadow;
                    }
                }
            }

            &-mobile {
                display: flex;
                flex-direction: column;
                height: $menu_main_height;

                &-wrap {
                    flex-grow: 1;
                    display: flex;
                }
                &-menu {
                    display: flex;
                    flex-direction: row;
                    align-self: center;

                    .nav-item, .lang-item {
                        margin: 0 .2rem;
                    }
                    .lang, .lang-item {
                        text-transform: uppercase;
                        padding: .4rem .6rem .4rem .6rem;
                        border-radius: 3px;

                        &.active, &.current-lang {
                            border: 1px solid $color_shadow;
                        }
                    }
                    .search {
                        font-size: 1.4rem;
                        position: relative;
                        width: 2.5rem;

                        form {
                            position: absolute;
                            top: -2px;
                            right: 0;
                            border-radius: 3px;
                        }

                        input {
                            display: none;
                            width: 150px;
                            border: none;
                        }

                        button {
                            background-color: #fff;
                        }

                        i {
                            font-size: 1.1rem;
                            cursor: pointer;
                        }
                    }

                    .search.open {
                        background-color: white;
                        flex-grow: 1;

                        form {
                            border: 1px solid $color_shadow;
                            display: flex;
                        }
                    }
                }
            }

            &-toggler {
                background-color: $color_background;
                border: none;
                border-radius: 0;
                align-self: flex-end;
                margin-right: 0.75rem;
                transition: all .1s ease-in;

                &:not(.collapsed) {
                    box-shadow: 0 0 13px 2px $color_shadow;
                }

                &-icon {
                    background-size: 20px;
                    background-image: url("/wp-content/themes/narodni_archiv/img/fake/toggler.png");
                }

                &-text {
                    color: $color_primary_dark;
                    font-size: 1.1rem;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }

    &__menucontent {
        position: absolute;
        width: $width_site_large;
        max-width: 99%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 29px;
        z-index: 20;
        box-shadow: 0 10px 15px 0px $color_shadow;
        @include media-breakpoint-down(md) {
            display: none;
        }

        .menu {
            display: none;
            background-color: $color_background;
            height: 650px;
            padding: 4rem 1.8rem 4rem 4rem;
            .row{
                margin-right: 0;
                margin-left: 0;
            }
            .menu-photo{
                padding-right: 2.2rem;
                padding-left: 0;
            }
            .image {
                height: 0;
                padding-bottom: 53%;
                background: 50% 50% no-repeat;
                background-size: cover;
            }
            .menu-items{
                padding-top: 2rem;
                .menu-item-col{
                    padding: 0;
                }
                .menu-item{
                    margin: 0 0 1em;
                    padding-left: 0;
                    padding-right: 2.2rem;
                    &.right{
                        float: right !important;
                    }
                    &.left{
                        float: left !important;
                    }
                    .item-top{
                        font-weight: 600;
                        font-size: 1.1rem;
                    }
                    .menu-items-children{
                        width: 100%;
                        display: inline-block;
                        padding-left: 1rem;
                        .item-child{
                            display: block;
                        }
                        .item-child.hidden{
                            display: none !important;
                        }
                    }
                }
            }
            &.item-1 .item-top:hover{
                text-decoration:  none;
                border-bottom: 4px solid $color_menu_1;
            }
            &.item-2 .item-top:hover{
                text-decoration:  none;
                border-bottom: 4px solid $color_menu_2;
            }
            &.item-3 .item-top:hover{
                text-decoration:  none;
                border-bottom: 4px solid $color_menu_3;
            }
            &.item-4 .item-top:hover{
                text-decoration:  none;
                border-bottom: 4px solid $color_menu_4;
            }
            &.item-5 .item-top:hover{
                text-decoration:  none;
                border-bottom: 4px solid $color_menu_5;
            }
        }
    }

    &__menuborder {
        position: absolute;
        width: $width_site_large;
        max-width: 99%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 25px;
        @include media-breakpoint-down(md) {
            display: none;
        }

        .menu-border {
            display: none;
            height: 4px;

            &.item-1 {
                background-color: $color_menu_1;
            }
            &.item-2 {
                background-color: $color_menu_2;
            }
            &.item-3 {
                background-color: $color_menu_3;
            }
            &.item-4 {
                background-color: $color_menu_4;
            }
            &.item-5 {
                background-color: $color_menu_5;
            }
        }
    }

    &__rotate {
        margin-top: 0.6rem;
    }

    &__rotate-angle {
        transform: rotate(90deg);
    }
}

.show-menu {
    font-size: 25px;
    font-weight: 600;
}