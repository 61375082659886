.news {
    &-container {
        padding: 2rem 0;
    }

    &-item {

        padding: 0 10px;

        &-thumb {
            position: relative;
            padding-top: 56.25%;

            .img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        &-title {
            border: 1px solid $color_shadow;
            border-top: none;
            padding: 1rem;
            font-weight: 700;
            height: 6.5rem;
            flex-grow: 1;
            @include media-breakpoint-down(md) {
                height: 9.5rem;
            }
        }
    }

    &-compact {
        &-top {
            display: flex;
  
            @media (min-width: 576px){
                max-width: 606px;
            }

            @media (min-width:  768px) {
                max-width: 780px;
            }

            @media (min-width:  991px) {
                max-width: 1045px;
            }

            @media (min-width:  1200px) {
                max-width: 1240px;
            }

            padding: 0;
        }

        &-button {
            display: flex;
            font-size: 5rem;
            align-items: center;
            cursor: pointer;
            &.button-left{
                margin: auto 20px auto 0;
            }
            &.button-right{
                margin: auto 0 auto 20px;
            }
        }

        &-dump {
            flex-grow: 1;
            flex-wrap: nowrap;
            overflow-x: hidden;
            display: none;
        }

        &-visible {
            flex-grow: 1;
            flex-wrap: nowrap;
            overflow-x: hidden;
            width: 576px;

            @include media-breakpoint-up(sm) {
                width: 720px;
            }

            @include media-breakpoint-up(md) {
                width: 980px;
            }

            @include media-breakpoint-up(lg) {
                width: 1140px;
            }


        }
    }
}