body {
    font-size: .95rem;
}

.container-sw {
    max-width: $width_site;
    margin: 0 auto;
}
.container-sw-large {
    max-width: $width_site_large;
    margin: 0 auto;
}

table {
    border-collapse: collapse;
    border-style: hidden;

    td:first-child {
        padding-left: 0;
    }
    td:last-child {
        padding-right: 0;
    }

    td {
        padding: .5rem;
    }

    td, tr {
        border: 1px solid $color_shadow;
    }
}

table.table-noborder {
    border: 0;
    td, th, tr {
        border: 0;
    }
}

.section {
    box-shadow: 0 0 20px 0px $color_shadow;
    border-top: 3px solid $color_shadow;
}

.xsection {
    &__title {
        box-shadow: inset 0 -13px 15px -10px $color_shadow;
        border-bottom: 2px solid $color_shadow;

        &__cont {
            margin: .75rem auto -2px auto;
        }

        &__text {
            background-color: $color_background;
            box-shadow: 0 0 13px 2px $color_shadow;
            border: 2px solid $color_shadow;
            border-bottom: none;
            display: inline-block;
            padding: .4rem 2rem 0 2rem;
            color: $color_primary;
            font-weight: 700;
            font-size: 1.1rem;
        }
    }

    &__content {
        z-index: 10;
        position: relative;
        background-color: $color_background;
    }
}

a {
    color: black;
}
a:hover {
    color: black;
}

#page-wrapper {

}

p{
    margin-bottom: .5rem;
}


.page {
    padding: 0 2rem 1rem 2rem;

    &__breadcrumbs {
        padding: 2rem 0;

        span {
            font-size: .85rem;

            i {
                padding: 0 .8rem;
            }
        }
    }

    &__content {
        @include media-breakpoint-up(md) {
            border-left: 1px solid $color_shadow;
            box-shadow: inset 10px 0 15px -12px $color_shadow;
            padding: 0 0 0 3rem;
        }
        padding: 1rem;
    }
}

.form__large{
    label{
        width: 100%;
        margin-bottom: 0;
        margin-top: .8rem;
        font-weight: 500;
    }

    select, input, textarea{
        width: 100%;
        margin: 2px 0;
        display: inline-block;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 3px #ddd;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 5px 10px;
    }
    textarea{
        padding: 6px 10px;
    }
    input[type="submit"]{
        width: auto;
        cursor: pointer;
    }
    h5 {
        margin-top: 0.5rem;
    }
}

#modal {
    .modal-content {
        border: none;
        border-radius: 0;
    }

    .modal-header {
        padding: 1rem 1.8rem;
        border-bottom: none;
    }

    h5 {
        border-bottom: 4px solid #d1262e;
        padding-bottom: 10px;
        font-weight: 700;
    }
    
    button {
        font-size: 2.5rem;
        padding-top: 0.5rem;
    }

    .modal-body {
        padding: 0.2rem 1.8rem 1rem 1.8rem;
    }

    @media (min-width: 576px){
        .modal-dialog {
            max-width: 600px;
        }
    }
}

.navbar {
    @media only screen and (max-width: 1199px) and (min-width: 992px)  {
        padding-left: 0;
    }
}
.navbar-brand {
    @media only screen and (max-width: 1199px) and (min-width: 992px)  {
        margin-right: 0;
    }
}

/* Gravity Forms */

.gform_wrapper {
    input[type=submit] {
        cursor: pointer;
        background-color: $color_primary;
        padding: .75rem 1rem;
        color: white;
        display: block;
        font-size: .8rem;
        font-weight: 600;
        text-transform: uppercase;
        border: none;
    }

    input[type=submit]:hover {
        text-decoration: underline;
        color: white;
    }
    ul.gfield_checkbox{
        li label{
            font-size: inherit;
        }
    }
    .gfield_checkbox li input[type=checkbox]{
        margin-top: 8px !important;
    }
    .gfield_checkbox li input, .gfield_checkbox li input[type=checkbox]{
        vertical-align: top   !important;
    }
    label.gfield_label {
        margin-bottom: 0;
    }
}

.gf-no-label {
    >label {
        display: none !important;
    }
}