.publications {
    .pub {
        padding: 1rem 0;
        border-bottom: 1px solid lighten(gray, 25%);

        .overlay{
            width: 100%;
            height: 230px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: 0;
            transition: opacity .3s ease;
            span.overlay-color {
                width: 100%;
                height: 100%;
                background: #fff;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                opacity: .6;
            }
            .list_btn{
                width: 100%;
                text-align: center;
                display: block;
                position: absolute;
                top: 25%;
                transition: top .3s ease;
                .pub__info__detail__btn{
                    display: block;
                    .btn{
                        margin: 1rem auto;
                    }
                    .btn_detail{
                        background-color: white;
                    }
                }
            }
        }
        &:hover .overlay{
            opacity: 1;
        }

        &.pub_small{
            border-bottom: none;
            padding: 0 1.2rem 2rem;

            .pb_small{
                position: relative;
            }
            .pub__info{
                padding: .5rem 0 0 0;
                .pub__info__title{
                    line-height: 1.45rem;
                    font-size: 1rem;
                    height: 2.95rem;
                    overflow: hidden;
                }
                .pub__info__detail__price{
                    text-transform: none;
                }
            }
            .pub__thumb{
                height: 230px;
                .image{
                    height: 100%;
                    background: 0 0 no-repeat;
                    background-size: contain;
                }
            }
        }

        &__thumb{
            height: 200px;
            .image{
                height: 100%;
                background: 50% 0 no-repeat;
                background-size: contain;
            }
        }

        &__info {
            padding: 1rem 1.5rem .2rem 1.5rem;
            display: flex;
            flex-direction: column;

            &__title {
                line-height: 1.6rem;
                font-weight: 700;
                font-size: 1.1rem;
                a{
                    color: black;
                }
            }

            &__desc {
                line-height: 1.6rem;
                text-align: justify;
                flex-grow: 1;
                text-align: left;
            }

            &__detail {
                &__price {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    span {
                        margin-left: .5rem;
                        text-transform: none;
                    }
                }

                &__btn {
                    display: flex;
                    justify-content: flex-end;

                    .btn {
                        width: 115px;
                        margin: 0 .5rem;
                        display: block;
                        border: 2px solid $color_primary;
                        padding: .45rem .9rem;
                        color: $color_primary;
                        border-radius: 0;
                        font-size: .80rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        transition: all .1s ease-in;
                    }

                    .btn:hover {
                        box-shadow: 0 0 10px -2px $color_primary;
                    }

                    .btn_buynow {
                        background-color: $color_primary;
                        color: white;
                    }
                }
            }
        }
    }
}

.publication_order_modal{
    .modal-header{
        padding: .5rem;
        border-bottom: none;
    }
    .modal-body{
        padding: 0 1.5rem 1.5rem;
        .modal__title.success{
            color: $color_menu_3;
        }
        .modal__title.failure{
            color: $color_menu_4;
        }
        .modal__order-item{
            padding-top: 1rem;
            .image{
                height: 100%;
                background: 50% 0 no-repeat;
                background-size: contain;
            }
            .item__title{
                font-size: 1.1rem;
            }
            .item__detail{
                margin: 3rem 0;
                span{
                    font-weight: 600;
                    margin-right: 1rem;
                }
            }
            .btn{
                width: 320px;
                margin: .5rem .5rem;
                border: 2px solid #037cc1;
                padding: .5rem 1rem;
                background-color: #037cc1;
                color: #fff;
                border-radius: 0;
                font-size: .8rem;
                font-weight: 700;
                text-transform: uppercase;
                -webkit-transition: all .1s ease-in;
                transition: all .1s ease-in;
                .fa{
                    font-size: 1rem;
                    margin-left: .8rem;
                }
                @include media-breakpoint-down(sm) {
                    white-space: unset;
                    width: 100%;
                }
            }
            .btn_close{
                background-color: #fff;
                color: #037cc1;
            }
        }
    }
}

.page__breadcrumbs.p_small{
    padding: 2rem 0 3.5rem;
    .order__in_count{
        padding: 1rem 0 0;
    }
}

.order__in_count{
    display: inline-flex;
    float: right;
    .circle__wrapper{
        border-radius: 50%;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        display: flex;
        border: 1px solid gray;
        .fa{
            font-size: 1.3rem;
        }
    }
    .order_text{
        display: flex;
        margin: auto 0 auto .5rem;
    }
    .order__count{
        color: red;
        font-weight: 700;
        margin: auto .5rem;
    }
}

.publications__form{
    margin: 1rem 0;
    .publication__order_item{
        padding: 1rem;
        margin: 1rem 0 0;
        border: 1px solid lighten(gray, 20%);
        .pub__info .row{
            height: 100%;
        }
        .image {
            height: 100%;
            width: auto;
            background: 50% 0 no-repeat;
            background-size: contain;
            min-height: 70px;
        }
        .title{
            font-size: .9rem;
            font-weight: 600;
            margin: auto 0;
        }
        .price{
            margin: auto 0;
        }
        @include media-breakpoint-up(md) {
            .count{
                padding: 0;
            }
        }
        .del{
            margin: auto 0;
        }
    }
}