.page__content{
    .entry-title{
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
    }

    .page__thumbnail{
        height: 20em;
        background-size: cover;
        background-position: center;
        width: 100%;
        border-radius: .2em;
        margin-bottom: 1.5em;
    }
    .post__date{
        font-weight: 600;
        margin-bottom: .5em;
    }
    a{
        font-weight: 600;
        color: $color_menu_5;
    }
    .back-to-top a{
        color: $color_shadow;
        &:hover{
            color: black;
        }
    }
}
.back-to-top{
    text-align: right;
    .top-button{
        display:none;
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
}

.row__official-desk {
    margin-bottom: 1rem;
}