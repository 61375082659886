.contact {
    margin: 0 -1rem;
    a {
        color: black;
    }
    h6{
        font-size: 1.05rem;
        padding-left: 1rem;
    }
    .department_content{
        padding: 0 1rem;
    }
    table{
        width: 100%;
        td{
            transition: all .2s ease-in;
        }
        td,tr{
            border: none;
            padding-top: .3rem;
            padding-bottom: .3rem;
        }
        td:nth-of-type(1){
            width: 30%;
        }
        .person_info td:nth-of-type(1), .detail_col{
            padding-left: 1rem;
        }
        td:nth-of-type(2){
            width: 15%;
        }
        td:nth-of-type(3){
            width: 25%;
        }
        td:nth-of-type(4){
            padding-right: 1rem;
        }
        @include media-breakpoint-down(sm) {
            width: max-content;
            td:nth-of-type(1){
                width: 25%;
            }
            td:nth-of-type(2){
                width: 25%;
            }
            td:nth-of-type(3){
                width: 25%;
            }
        }
        &.person_table .person_info{
            td:hover{
                text-decoration: none;
            }
            .surname{
                text-transform: uppercase;
            }
        }
        .contact-collapse{
            font-weight: normal;
            width: 100%;
            display: inline-block;
        }
        tr.spacer{
            height: 1rem;
        }

        tr.detail_info .show_more_info{
            float: right;
            .fa{
                color: gray;
                font-weight: 600;
                font-size: 1.1rem;
            }
        }
        tr.detail_info.detail_shown .show_more_info{
            transform: rotate(90deg);
        }
        .detail_col{
            padding-top: 0;
            img{
                float: right;
                margin: 25px 15px;
            }
        }
        .collapse.show{
            padding-bottom: 1rem;
            border-bottom: 1px solid $color_shadow;
            margin-left: -1rem;
            margin-top: -1rem;
            background-color: lighten(gray, 43%);
            min-height: 350px;
            .contact_info{
                margin: .5rem 1rem ;
                padding-top: .5rem;
                &:first-of-type{
                    border-top: 1px solid gray;
                }
            }
        }
        tr.detail_shown{
            background-color: lighten(gray, 43%);
            color: $color_primary;
            font-weight: 600;
            box-shadow: 0 -5px 6px -2px $color_shadow;
            border-top: 2px solid $color_primary;
            td{
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            a{
                color: $color_primary;
                font-weight: 600;
            }
            &:hover td{
                background-color: lighten(gray, 43%);
            }
        }
    }

    .select_wrapper{
        padding-left: 1rem;
        padding-bottom: 2rem;
        label{
            padding-right: 1rem;
        }
        select{
            padding: .5rem 1rem;
            font-weight: 600;
            border-radius: 5px;
        }
        option{
            padding: .5rem 1rem;
        }
    }
}

.department{
    padding: 1rem 0;
    @include media-breakpoint-down(sm) {
        overflow-x: scroll;
    }

    .person_table{
        padding-bottom: 4rem;
    }

    .person_info{
        &:hover{
            td{
                background-color: lighten(gray, 43%);
            }
        }
    }
    .text-name{
        a{
            color: black;
            font-weight: normal;
            display: block;
        }
    }
    .text-phone {
        white-space: nowrap;
    }
    .info_title{
        text-transform: uppercase;
    }
}