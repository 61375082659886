
.search-page{
    form{
        margin-bottom: 2rem;
        border-radius: 3px;
        border: 1px solid #ddd;
    }
    .navbar__search-btn{
        background-color: #fff;
    }
    .field-search{
        border: none;
        width: 100%;
        padding: 0 .5rem;
    }
}
