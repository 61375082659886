.current {
    &-container {
        padding-top: 2rem;
        padding-bottom: 2rem;

    }

    &__article {
        &__title {
            text-transform: uppercase;
            letter-spacing: 1px;
            margin: 1rem 0;
        }

        &__content {
            border: 1px solid $color_shadow;
            position: relative;
            padding-top: 40%;
            min-height: 200px;
            @include media-breakpoint-down(sm) {
                min-height: 300px;
            }
            &:hover{
                .title{
                    text-decoration: underline;
                }
            }

            &_ {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                &.row{
                    margin: 0;
                }
            }
        }

        &__text {
            padding: .5rem 2rem;
            @include media-breakpoint-down(md) {
                padding: .4rem 1.5rem;
            }

            .title {
                color: $color_primary;
                font-weight: 600;
                padding: .8rem 0;
                @include media-breakpoint-down(md) {
                    padding: .5rem 0;
                }
            }

            .text {
                font-size: .9rem;
            }
        }

        &__thumb {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 100px;
        }
    }
}