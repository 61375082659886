.page__menu{
    padding-right: 0;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li{
            padding: .2rem 2rem .2rem 0;

            a{
                display: block;
            }
        }

        ul{
            margin-right: -2rem;
            padding-left: 1.5rem;
            font-size: .9rem;
            a{
                padding: .2rem 0;
            }
            ul{
                margin-right: -2rem;
                padding-left: 1.5rem;
            }
        }
    }

    //First menu level
    .menu > li {

        &:not(:first-child) > a{
            border-top: 1px solid $color_shadow;
        }

        & > a{
            padding: .5rem 0;
            font-size: .9rem;
            align-items: center;
            cursor: pointer;
        }
    }

    // Items with children
    .menu-item-has-children > a{
		&:after {
            color: gray;
			font-family: fontAwesome;
			content: $fa-var-chevron-right;
			display: block;
			text-decoration: none;
			margin: 3px 0 0;
            font-size: .8rem;
            float: right;
            transition: all .2s ease-in;
		}
    }

    // When children of menu are shown - chevron down, bold font
    .current_page_ancestor.menu-item-has-children > a, .current_page_item.menu-item-has-children > a,
    .current_page_parent.menu-item-has-children > a, 
    .current-menu-parent.publication-ancestor.menu-item-has-children > a{
        font-weight: 700;
        &:after {
            transform: rotate(90deg);
        }
    }

    // On hover only change arrow
    // .menu-item-has-children:hover a:after{
    //     transform: rotate(90deg);
    // }
    
    // Active menu item colors
    .item-1 .current_page_item, .item-1 .current-menu-item, .item-1 .current-post-parent.menu-item-type-taxonomy{
        border-right: 4px solid $color_menu_1;
        padding-right: calc(2rem - 4px);
        & > a{
            font-weight: 700;
        }
    }

    .item-2 .current_page_item, .item-2 .current-menu-item, .item-2 .current-post-parent.menu-item-type-taxonomy{
        border-right: 4px solid $color_menu_2;
        padding-right: calc(2rem - 4px);
        & > a{
            font-weight: 700;
        }
    }

    .item-3 .current_page_item, .item-3 .current-menu-item, .item-3 .current-post-parent.menu-item-type-taxonomy{
        border-right: 4px solid $color_menu_3;
        padding-right: calc(2rem - 4px);
        & > a{
            font-weight: 700;
        }
    }

    .item-4 .current_page_item, .item-4 .current-menu-item, .item-4 .current-post-parent.menu-item-type-taxonomy,
    .item-4 .current-publication-ancestor{
        border-right: 4px solid $color_menu_4;
        padding-right: calc(2rem - 4px);
        & > a{
            font-weight: 700;
        }
    }

    .item-5 .current_page_item, .item-5 .current-menu-item, .item-5 .current-post-parent.menu-item-type-taxonomy{
        border-right: 4px solid $color_menu_5;
        padding-right: calc(2rem - 4px);
        & > a{
            font-weight: 700;
        }
    }

    //Hide other menu
    .menu-item{
        display: none;
    }

    .current-menu-item, .current-menu-ancestor, .current-menu-item > .sub-menu > .menu-item, 
    .current-menu-ancestor > .menu-item, .current-menu-item ~ .menu-item, 
    .current-menu-ancestor ~ .menu-item, .current_page_parent, .current_page_parent > .menu-item,
    .current-menu-parent, .current-menu-parent > .menu-item {
        display: block;
    }

    //Show on hover submenu
    // .menu-item-has-children:hover > .sub-menu > .menu-item{
    //     display: block;
	// }

    //Hide hidden menu items
    .menu-item.hidden {
        display: none !important;
    }

}