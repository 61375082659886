.openhours {
    &__text {
        padding-top: 1.5rem;
        display: flex;
        justify-content: center;
        font-size: .85rem;

        i {
            font-size: 1.3rem;
            margin-right: 1rem;
        }
    }
}