.category__content{
    .entry-title{
        font-size: 1.8rem;
        font-weight: 700;
    }

    .block__item{
        padding: 2rem 0;
        border-bottom: .5px solid #aaa;
        .block_item__title{
            font-weight: 700;
            color: $color_menu_5;
            font-size: 1.3rem;
            margin-bottom: .5rem;
        }
        .post_date{
            font-weight: 600;
            font-size: .85rem;
        }
        .img{
            padding: 0;
            margin-top: .5rem;
        }
        .block_image{
            height: 0;
            padding-bottom: 65%;
            background: 50% 50% no-repeat;
            background-size: cover;
        }
    }
}