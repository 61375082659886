.menupage {
    .menu {
        background-color: $color_background;
        height: auto;

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        @include media-breakpoint-down(md) {
            .menu-photo {
                padding: 1rem 0;
            }
        }
        
        @include media-breakpoint-up(lg) {
            .menu-photo:first-child {
                padding-right: 2.2rem;
                padding-left: 0;
            }
            .menu-photo:nth-child(2) {
                padding-right: 1.1rem;
                padding-left: 1.1rem;
            }
            .menu-photo:last-child {
                padding-left: 2.2rem;
                padding-right: 0;
            }
        }

        .image {
            height: 0;
            padding-bottom: 53%;
            background: 50% 50% no-repeat;
            background-size: cover;
        }
        .menu-items {
            padding-top: 2rem;
            -moz-column-count: 3;
            -webkit-column-count: 3;
            column-count: 3;
            -moz-column-gap: 3.3em;
            -webkit-column-gap: 3.3em;
            column-gap: 3.3em;
            .menu-item {
                width: 100%;
                display: inline-block;
                margin: 0 0 1em;
                .item-top {
                    font-weight: 600;
                    font-size: 1.1rem;
                }
                .menu-items-children {
                    width: 100%;
                    padding-left: 1rem;
                    .item-child {
                        display: block;
                    }
                }
            }
        }
        &.item-1 .item-top:hover {
            text-decoration: none;
            border-bottom: 4px solid $color_menu_1;
        }
        &.item-2 .item-top:hover {
            text-decoration: none;
            border-bottom: 4px solid $color_menu_2;
        }
        &.item-3 .item-top:hover {
            text-decoration: none;
            border-bottom: 4px solid $color_menu_3;
        }
        &.item-4 .item-top:hover {
            text-decoration: none;
            border-bottom: 4px solid $color_menu_4;
        }
        &.item-5 .item-top:hover {
            text-decoration: none;
            border-bottom: 4px solid $color_menu_5;
        }
    }
}