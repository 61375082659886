.slider {
    touch-action: none;

    &__bg {
        flex-wrap: nowrap;
        overflow: hidden;
        box-shadow: 0 5px 10px 0px $color_shadow;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 400px;

        .bg {
            height: 400px;
            background-position: center;
            background-size: cover;
            overflow: hidden;

        }
    }

    &__video {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        right: 0;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__content {
        margin: 0 auto;
        position: relative;
        @include media-breakpoint-down(md) {
            flex-wrap: nowrap;
            overflow-x: hidden;
            margin-top: -300px;
            padding-top: 300px;
        }

        .content {
            padding: 0;
            transition: box-shadow .1s ease-in;

            /* Fix right padding when overflow-x:hidden is used */
            &[data-item="3"]:after {
                content: "";
                display: block;
                position: absolute;
                right: -2rem;
                width: 2rem;
                height: 1px;
            }

            &__slide {
                display: none;
                position: absolute;
                bottom: 100%;
                right: 0;
                left: 0;
                padding: .5rem 1rem;
                background-color: $color_background;
                border-bottom: 1px solid $color_shadow;
                overflow: hidden;
            }

            &__title {
                font-weight: 600;
                font-size: 1.2rem;
                padding: .6rem 0; 
            }

            &__info {
                height: 100%;
                font-weight: 600;
                background-color: $color_background;
                border-bottom: 1px solid $color_shadow;
                //border-bottom: 5px solid $color_background;
                padding: .5rem 1rem 7px + 5px;
                z-index: 1;
                position: relative;
                transition: all .1s ease-in;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            &.open {
                z-index: 2;
                box-shadow: 0 0 15px 2px $color_shadow;
                .content__slide {
                    box-shadow: 0 0 15px 2px $color_shadow;
                }
            }

            .more_link {
                text-decoration: underline;
            }
        }

        .content:first-child .content__info{
            border-left: 1px solid $color_shadow;
        }
        .content:last-child .content__info{
            border-right: 1px solid $color_shadow;
        }
        .content:not(:first-child) .content__info{
            border-left: .5px solid $color_shadow;
        }
        .content:not(:last-child) .content__info{
            border-right: .5px solid $color_shadow;
        }

        .content.open[data-item="1"] {
            .content__info {
                border-bottom: 5px solid $color_menu_1;
                padding: .5rem 1rem 8px;
            }
        }
        .content.open[data-item="2"] {
            .content__info {
                border-bottom: 5px solid $color_menu_2;
            }
        }
        .content.open[data-item="3"] {
            .content__info {
                border-bottom: 5px solid $color_menu_3;
            }
        }
    }
}
