.publication {
    .pub {
        margin: 2rem 0;

        &__d {
            margin: 0;

            &__thumb {
                padding: 0;
            }

            &__info {
                @include media-breakpoint-up(lg) {
                    padding: 0 0 0 1.5rem;
                }
                display: flex;
                flex-direction: column;

                &__title {
                    font-weight: 600;
                    line-height: 2rem;
                    @include media-breakpoint-down(md) {
                        margin-top: 2rem;
                    }
                }

                &__detail {
                    flex-grow: 1;

                    &__table {
                        font-size: 1rem;

                        table{
                            display: inline;
                        }

                        td.h {
                            font-weight: 600;
                        }

                        td {
                            padding: .2rem .3rem;
                        }

                        .price {
                            margin-top: 1rem;
                            font-size: 1.2rem;
                            font-weight: 700;
                        }
                    }

                    &__btn {
                        position: absolute;
                        bottom: 0;    
                        right: 15px;
                        @include media-breakpoint-down(md) {
                            margin-top: .5rem;
                        }

                        .btn {
                            display: block;
                            border: 2px solid $color_primary;
                            padding: .45rem .9rem;
                            background-color: $color_primary;
                            color: white;
                            border-radius: 0;
                            font-size: .80rem;
                            font-weight: 700;
                            text-transform: uppercase;
                            transition: all .1s ease-in;
                        }
    
                        .btn:hover {
                            box-shadow: 0 0 10px -2px $color_primary;
                        }
                    }
                }
            }
        }

        &__desc {
            padding: 2rem 0;
            line-height: 1.55rem;
        }
    }
}