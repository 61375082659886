.wrapper-footer {
    .footer {
        padding: 0 1rem;
        background-color: $color_footer;
        box-shadow: 0 0 20px 0px $color_shadow;
        border-top: 2px solid $color_shadow;

        ul {
            list-style-type: none;
            font-size: .9rem;
            line-height: 1.5rem;
            margin: 0;
            padding: 0;
        }
        @include media-breakpoint-down(md) {
            ul {
                line-height: 1.8rem;
            }
        }


        &__top {
            padding: 1rem 0;

            .title, h3 {
                color: $color_primary;
                font-weight: 700;
                font-size: 1.2rem;
                padding: .5rem 0;
            }

            .button {
                margin-top: 1.6rem;
                display: inline-block;

                a {
                    background-color: $color_primary;
                    padding: .6rem .9rem;
                    color: white;
                    font-size: .8rem;
                    font-weight: 600;
                    margin: 0;
                    box-shadow: 0 0 13px 2px $color_shadow;
                    text-transform: uppercase;
                }

                &.button_right{
                    float: right;
                }

                @include media-breakpoint-up(lg) {
                    &.button_one{
                        display: block;
                    }
                }
                @include media-breakpoint-down(lg) {
                    &.button_right{
                        float: none;
                    }
                }

            }
            .button_block{
                padding: 0 1rem .5rem;
            }


            @include media-breakpoint-down(md) {
                .button:last-of-type{
                    margin-bottom: 2rem;
                }
            }

            .newsletter{
                padding-top: 1rem;
                h3{
                    padding-bottom: 0;
                    color: $color_primary;
                }
                p{
                    font-size: .9rem;

                }
                .fa{
                    color: gray;
                    font-weight: 700;
                }
                form{
                    display: flex;
                    align-items: center;
                    border-radius: 1px;
                    border: 1px solid #ddd;
                    margin: 1rem 0 0 0;
                    input {
                        font-size: .9rem;
                        border: none;
                        border-radius: 0;
                        padding: 0 .5rem;
                    }
                    button{
                        border-radius: 0;
                        background-color: darken($color: $color_footer, $amount: 10%);
                    }
    
                    .fa {
                        font-size: 1.1rem;
                        cursor: pointer;
                        color: $color_primary;
                    }
                }
            }

            .maps{
                padding: 1rem 1rem;
                position: relative;
                .map {
                    margin: 0;
                    border: 1px solid $color_shadow;
    
                    img{
                        cursor: pointer;
                        width: 100%;
                        height: auto;
                    }
    
                    p{
                        margin-bottom: 0;
                    }
    
                    p:nth-of-type(2) {
                        font-size: .85rem;
                        padding: .4rem .5rem;
                        border-top: 1px solid $color_shadow;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .maps{
                    padding: 1rem 1rem 0;
                    bottom: 0;
                }
            }
            .podatelna,
            .badatelna,
            .knihovna,
            .newsletter {
                padding: 0 1rem .5rem 1rem;
                font-size: .9rem;
                
                ul{
                    padding-bottom: .7rem;
                }

                .desc {
                    padding-top: 1.5rem;
                }

                p{
                    margin-bottom: .5rem;
                }

                table{
                    border-top: 1px solid rgba(0,0,0,.4);
                    border-bottom: 1px solid rgba(0,0,0,.2);
                    td{
                        padding: .4rem .5rem;
                    }
                    tr:first-of-type td{
                        border-top: none;
                    }
                } 

                .openhours {
                    text-transform: uppercase;
                    cursor: pointer;
                    padding-top: 1rem;
                    padding-bottom: 1rem;

                    i {
                        font-size: 1.2rem;
                        margin-right: .2rem;
                    }
                }
            }

            .fw {
                width: auto;
            }

            @include media-breakpoint-up(lg) {
                .bl {
                    border-left: 1px solid $color_shadow;
                }
            }

        }

        .podatelna{
            padding-bottom: 0;
        }

        &__bot {
            padding: 1rem 0;

            .address {
                padding: 0 0 0 2rem;
                font-size: .9rem;

                p{
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-down(md) {
                .logo,
                .address,
                .buttons {
                    margin: .8rem 0;
                }
            }

        }
    }
    .counter{
        margin:  0 1rem .5rem;
        padding-left: 1rem;
        border: 1px solid  $color_shadow;
        background-color: #fff;
        border-radius: 0;
        padding-top: .5rem;

        & > div{
            padding: 5px 3px;
            border-radius: 3px;
            display: inline-block;
            text-align: center;
        }
        
        & div > span{
            padding: 5px 10px;
            border-radius: 3px;
            background: lighten($color_menu_5, 5%);
            display: inline-block;
            color: white;
            font-size: 1rem;
            width: 35px;
        }
        
        .smalltext{
            padding-top: 4px;
            font-size: .8rem;
        }
        .countdown_text{
            font-size: .9rem;
            border: none;
            text-align: left;
        }
    }
}